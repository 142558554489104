export default class {
  static spec(taskCard) {
    const members = taskCard.members
    // let memberViews = null
    if (members.length > 0) {
      return {
        "view": "panels/horizontal",
        // "padding": { right: 1 },
        "styleClasses": [
          "no-hover"
        ],
        "distribution": "overlap-2",
        "childViews": taskCard.members.map((member) => {
          return {
            "view": "avatar",
            "backgroundColor": "#dedede",
            "url": member.avatar,
            "size": 26
          }
        }),
        "onClick": this.memberPicker(taskCard)
      }
    } else {
      return {
        "view": "button",
        "styleClasses": [
          "icon",
          "x-small",
          "text"
        ],
        "icon": {
          "color": "#dedede",
          "material": {
            "name": $tpu.keys.icnAddMember(),
            // "size": 26
            "size": 24
          },
        },
        "onClick": this.memberPicker(taskCard)
      }
    }
  }

  static memberPicker(card) {
    const memberIds = card.members.map(m => m.id)
    return {
      "action": "dialogs/show",
      "closeOnBlur": true,
      "body": {
        "padding": {
          "top": 18,
          "left": 20,
          "right": 20,
          "bottom": 22
        },
        "childViews": [
          {
            "view": "panels/form",
            "width": "matchParent",
            "url": null,
            "method": "patch",
            "childViews": [
              {
                "view": "panels/vertical",
                "childViews": $tpu.urls.boardMembers().map((member) => {
                  return {
                    "view": "fields/check",
                    "checkValue": true,
                    "uncheckValue": false,
                    "name": "assigned",
                    "label": member.fullName,
                    "value": memberIds.includes(member.id),
                    "onChange": {
                      "action": "commands/custom",
                      "name": "cardMembers/update",
                      "properties": {
                        "cardId": card.id,
                        "memberId": member.id,
                      }
                    },
                  }
                })
              },
            ],
            "onSubmit": {
              "action": "commands/custom",
              "name": "cardMembers/update",
              "properties": {
                "cardId": card.id
              }
            },
          }
        ]
      }
    }
  }
}
